<template>
  <section class="page__section">
    <div class="container">
      <h2 class="text-center mt-0">
        {{ $t("home_page.titles.section-realizacao") }}
      </h2>
      <hr class="divider my-4" />
      <div class="page__section__cards page__section__cards__3">
        <div
          class="page__section__cards__card"
          v-for="card in cards"
          :key="card.icon"
        >
          <a
            :href="card.link"
            v-if="card.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              :src="require(`../../assets/img/realizacao/${card.img}`)"
              alt=""
            />
          </a>
          <img
            v-else
            :src="require(`../../assets/img/realizacao/${card.img}`)"
            alt=""
          />
          <h3>{{ card.title }}</h3>
          <p>{{ card.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      titlePage: "Realização",
      cards: [
        {
          img: "ifce.svg",
          link: "https://ifce.edu.br/",
          title: "IFCE",
          description:
            "Instituto Federal de Educação, Ciência e Tecnologia do Ceará"
        },
        {
          img: "lar.svg",
          link: "",
          title: "LAR",
          description: "Laboratório de Redes de Computadores e Sistemas"
        },
        {
          img: "lit.png",
          link: "",
          title: "LIT",
          description: "Laboratório de Inovação Tecnológica"
        }
      ]
    };
  }
};
</script>
