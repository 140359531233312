<template>
  <section class="pt-5 pb-5">
    <div class="container">
      <h2 class="text-center mt-0">
        {{ $t("home_page.titles.section-contato") }}
      </h2>
      <hr class="divider my-4" />
      <VueFaqAccordion
        class="faq-wrapper"
        :items="myItems"
        :activeColor="'#27ae60'"
        :borderColor="'#c7c7c7'"
      />
    </div>
  </section>
</template>

<script>
import VueFaqAccordion from "vue-faq-accordion";

export default {
  components: {
    VueFaqAccordion
  },
  data() {
    return {
      titlePage: "Perguntas Frequentes",
      myItems: [
        {
          title: `...`,
          value: ``,
          category: ``,
          translate_key: "o-que-e"
        },
        {
          title: `...`,
          value: ``,
          category: ``,
          translate_key: "como-cadastrar"
        },
        {
          title: `...`,
          value: ``,
          category: ``,
          translate_key: "devo-pagar"
        },
        {
          title: `...`,
          value: ``,
          category: ``,
          translate_key: "precisa-cnpj"
        },
        {
          title: `...`,
          value: ``,
          category: ``,
          translate_key: "como-alterar"
        },
        {
          title: `...`,
          value: ``,
          category: ``,
          translate_key: "como-funciona-negociacao"
        },
        {
          title: `...`,
          value: ``,
          category: ``,
          translate_key: "como-apoiar"
        }
      ]
    };
  },
  mounted() {
    setTimeout(this.translateFaq, 1000);
  },
  methods: {
    async translateFaq() {
      for (let key of this.myItems) {
        key.title = await this.$t(
          "home_page.faq.perguntas." + key.translate_key
        );
        key.value = await this.$t(
          "home_page.faq.respostas." + key.translate_key
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .faq-wrapper {
  border: none;
  max-width: 100% !important;
}
/deep/ .accordion__title-text {
  text-align: left;
  border: hidden;
}
</style>
