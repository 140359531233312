<template>
  <main class="main">
    <div class="container">
      <b-form-group
        :label="$t('home_page.labels.busca-principal-top')"
        label-size="lg"
        style="width: 100%"
        class="cidade-input"
      >
        <div class="row">
          <div class="col-lg-6 mt-1">
            <v-select
              id="input-1"
              v-model="estadoFilter"
              :options="estados"
              :placeholder="$t('geral.placeholders.selecione-estado')"
              label="name"
              @input="changedEstado()"
            ></v-select>
          </div>
          <div class="col-lg-6 mt-1">
            <v-select
              id="input-1"
              v-model="cidadeFilter"
              :options="cidades"
              :placeholder="$t('geral.placeholders.selecione-cidade')"
              :disabled="!cidades.length > 0"
              label="nome"
            ></v-select>
          </div>
        </div>
      </b-form-group>
      <!-- <h1 class="mb-5">Pesquise aqui um serviço na sua cidade</h1> -->
      <b-form-group
        :label="$t('home_page.labels.busca-principal-mid')"
        label-size="lg"
        style="width: 100%"
        class="servico-input"
      >
        <b-input-group>
          <b-form-input
            @keyup.enter="goToView"
            v-model="search"
            :placeholder="$t('geral.placeholders.procurando')"
          ></b-form-input>
          <b-input-group-append>
            <b-button size="sm" @click="goToView" text="Button" variant="light">
              <b-icon-search />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <p class="label-alternativo">
        {{ $t("home_page.labels.busca-principal-bottom") }}
      </p>
      <div class="card-list-category">
        <div
          class="card-list-category-item p-3"
          @click="setAndSend(item.obj.id, $t(`categorias.${item.obj.nome}`))"
          v-for="item in cards"
          :key="item.obj.id"
        >
          <!-- <b-icon-gem class="card-list-category-item-icon mt-3" /> -->
          <img
            :src="
              require(`../../assets/icons/categorias_color/${item.img}.svg`)
            "
            class="mt-4"
            alt=""
            width="60"
          />
          <span
            class="card-list-category-item-text mb-2 mt-1"
            style="font-size: 0.85rem;"
            >{{ $t("categorias." + item.obj.nome) }}</span
          >
        </div>
        <div class="card-list-category-item p-3" @click="setAndSend('')">
          <img
            :src="
              require(`../../assets/icons/categorias_color/ver-todos-color.svg`)
            "
            class="mt-4"
            alt=""
            width="60"
          />
          <span
            class="card-list-category-item-text mb-2 mt-1"
            style="font-size: 0.85rem;"
            >{{ $t("categorias.ver-todos") }}
          </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Service from "../../services/ApiServices";

export default {
  data() {
    return {
      estados: [],
      cidades: [],
      search: "",
      estadoFilter: "",
      cidadeFilter: "",
      tipoEstabelecimentoFilter: "",
      cards: [
        //  linha 1
        {
          obj: { id: "5e7e9043ab88c1557273ac51", nome: "agua" },
          img: "garrafao-agua-color"
        },
        {
          obj: { id: "5e83aff09747ca74a3139c27", nome: "alimentacao" },
          img: "alimentacao-color"
        },
        {
          obj: { id: "5e7e6c60ab88c1557273ac09", nome: "restaurante" },
          img: "restaurant-color"
        },
        {
          obj: { id: "5e7e6c4aab88c1557273ac06", nome: "lanchonete" },
          img: "lanchonete-color"
        },
        {
          obj: { id: "5e7f819e33af966e5ba9f465", nome: "marmita" },
          img: "marmita-color"
        },
        {
          obj: { id: "5e7e6c65ab88c1557273ac0a", nome: "supermercado" },
          img: "supermercado-color"
        },
        // linha 2
        {
          obj: { id: "5e7e6c53ab88c1557273ac07", nome: "padaria" },
          img: "padaria-color"
        },
        {
          obj: { id: "5e7e6c57ab88c1557273ac08", nome: "pizzaria" },
          img: "pizzaria-color"
        },
        {
          obj: { id: "5e83f8ce9747ca74a3139c2d", nome: "acougue-peixaria" },
          img: "acougue-color"
        },
        {
          obj: { id: "5e7e6c3bab88c1557273ac04", nome: "farmacia" },
          img: "farmacia-color"
        },
        {
          obj: { id: "5e83e9d29747ca74a3139c2c", nome: "saude" },
          img: "saude-color"
        },
        {
          obj: { id: "5e83b0049747ca74a3139c28", nome: "pet" },
          img: "pet-color"
        },
        // linha 3
        {
          obj: { id: "5e83facd9747ca74a3139c32", nome: "informatica" },
          img: "informatica-color"
        },
        {
          obj: { id: "5e83e9c99747ca74a3139c2b", nome: "limpeza" },
          img: "limpeza-color"
        },
        {
          obj: { id: "5e83f8fe9747ca74a3139c30", nome: "papelaria-livraria" },
          img: "livraria-color"
        },
        {
          obj: { id: "5e83f9079747ca74a3139c31", nome: "servicos" },
          img: "servicos-color"
        },
        {
          obj: { id: "5e7e8d39ab88c1557273ac44", nome: "outros" },
          img: "outros-color"
        }
      ]
    };
  },

  watch: {
    cidadeFilter: function() {
      if (this.cidadeFilter == null) {
        this.cidadeFilter = "";
        localStorage.removeItem("ultimaCidadeSelecionada");
      } else if (this.cidadeFilter && this.cidadeFilter != "")
        localStorage.setItem(
          "ultimaCidadeSelecionada",
          JSON.stringify(this.cidadeFilter)
        );
    }
  },

  async created() {
    this.localizacaoUsuario();
    //se tiver cidade e não tiver estado, remove a cidade
    if (
      localStorage.ultimaCidadeSelecionada &&
      localStorage.ultimaCidadeSelecionada != null &&
      !(
        localStorage.ultimoEstadoSelecionado &&
        localStorage.ultimoEstadoSelecionado != null
      )
    ) {
      localStorage.removeItem("ultimaCidadeSelecionada");
    }

    if (
      localStorage.ultimoEstadoSelecionado &&
      localStorage.ultimoEstadoSelecionado != null
    )
      this.estadoFilter = JSON.parse(localStorage.ultimoEstadoSelecionado);

    if (
      localStorage.ultimaCidadeSelecionada &&
      localStorage.ultimaCidadeSelecionada != null
    )
      this.cidadeFilter = JSON.parse(localStorage.ultimaCidadeSelecionada);

    if (this.estadoFilter != "") this.getCidades();
    this.getEstados();
  },

  methods: {
    goToView() {
      this.$router.push({
        name: "Servicos",
        params: {
          search: this.search,
          tipoEstabelecimento: this.tipoEstabelecimentoFilter,
          cidadeFilter: this.cidadeFilter,
          estadoFilter: this.estadoFilter
        }
      });
    },

    setAndSend(id, nome) {
      this.tipoEstabelecimentoFilter = { id: id, nome: nome };
      this.goToView();
    },

    getEstados() {
      const request = new Service();
      request
        .getAll({ sort: "nome", size: 27 }, "estados")
        .then(res => (this.estados = res.content));
    },

    getCidades() {
      const request = new Service();
      if (this.estadoFilter && this.estadoFilter.name)
        request
          .getAll(
            { sort: "nome", nomeEstado: this.estadoFilter.name },
            "cidades/findByEstado"
          )
          .then(res => (this.cidades = res.content));
    },

    changedEstado() {
      this.cidades = [];
      if (this.estadoFilter == null) {
        this.estadoFilter = "";
        localStorage.removeItem("ultimoEstadoSelecionado");
      } else if (this.estadoFilter && this.estadoFilter != "")
        localStorage.setItem(
          "ultimoEstadoSelecionado",
          JSON.stringify(this.estadoFilter)
        );

      this.cidadeFilter = "";
      localStorage.removeItem("ultimaCidadeSelecionada");
      this.getCidades();
    },

    localizacaoUsuario() {
      if (
        !localStorage.ultimoEstadoSelecionado &&
        !localStorage.ultimaCidadeSelecionada
      ) {
        const request = new Service();
        navigator.geolocation.getCurrentPosition(async position => {
          const bigDataLocalizationResp = await request.buscaCidadeByCordenadasBigDataCloud(
            position.coords.latitude,
            position.coords.longitude
          );
          const cidadeResp = await request.getAll(
            {
              estado: bigDataLocalizationResp.principalSubdivision,
              cidade: bigDataLocalizationResp.city
            },
            "cidades/findByCidadeAndEstado"
          );
          if (cidadeResp) {
            this.estadoFilter = cidadeResp.state;
            localStorage.setItem(
              "ultimoEstadoSelecionado",
              JSON.stringify(this.estadoFilter)
            );
            this.cidadeFilter = cidadeResp;
            localStorage.setItem(
              "ultimaCidadeSelecionada",
              JSON.stringify(this.cidadeFilter)
            );
          }
        });
      }

      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(this.showPosition);
      // }
    }

    // async showPosition(position) {
    //     const request = new Service();
    //     console.log("Latitude: " + position.coords.latitude);
    //     console.log("Longitude: " + position.coords.longitude);
    //     const resp = await request.buscaCidadeByCordenadasBigDataCloud(position.coords.latitude, position.coords.longitude);
    //     console.log("Estado: "+resp.principalSubdivision);
    //     console.log("Cidade: "+resp.city);
    //   }
  }
};
</script>
