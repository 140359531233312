<template>
  <section class="page__section page__section--cinza">
    <div class="container">
      <h2 class="text-center mt-0">
        {{ $t("home_page.titles.section-vantagens") }}
      </h2>
      <hr class="divider my-4" />
      <div class="d-flex justify-content-center">
        <b-form-group
          label=""
          label-for="input-3"
          class="col-lg-5 mt-1 text-center pb-4"
        >
          <b-form-radio-group
            class="text-center"
            id="input-3"
            v-model="content"
            :options="contents"
            label="label"
            buttons
            button-variant="outline-success"
            size="lg"
          ></b-form-radio-group>
          <br />
        </b-form-group>
      </div>
      <div class="page__section__cards">
        <div
          class="page__section__cards__card"
          v-for="card in cards"
          :key="card.icon"
        >
          <b-icon :icon="card.icon" />
          <h3>{{ $t("home_page.labels." + card.translate_key) }}</h3>
          <p>
            {{
              $t("home_page.descricoes.section-vantagem-" + card.translate_key)
            }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      titlePage: "Vantagens de Usar o #FiqueNoLar",
      cardsCli: [
        {
          icon: "search",
          translate_key: "encontre"
        },
        {
          icon: "wallet",
          translate_key: "gratuito"
        },
        {
          icon: "chat-dots",
          translate_key: "servicos-der"
        },
        {
          icon: "heart-fill",
          translate_key: "com-amor"
        }
      ],
      cardsVen: [
        {
          icon: "gem",
          translate_key: "visibilidade"
        },
        {
          icon: "wallet",
          translate_key: "gratuito"
        },
        {
          icon: "briefcase-fill",
          translate_key: "perspectiva"
        },
        {
          icon: "heart-fill",
          translate_key: "com-amor"
        }
      ],
      content: true,
      contents: [
        { text: "", value: true, translate_key: "p-vendedor" },
        { text: "", value: false, translate_key: "p-cliente" }
      ]
    };
  },
  mounted() {
    this.translateRadio();
  },
  methods: {
    translateRadio() {
      for (let key of this.contents) {
        key.text = this.$t("home_page.labels." + key.translate_key);
      }
    }
  },
  computed: {
    cards() {
      return this.content ? this.cardsVen : this.cardsCli;
    }
  }
};
</script>
