<template>
  <div class="home__page">
    <navbar />
    <main-homepage />
    <cadastre-empresa />
    <download />
    <dicas />
    <vantagens />
    <realizacao />
    <parceria />
    <apoio />
    <estatisticas />
    <faq />
    <contato />
    <footer-homepage />
    <!--
    <div>
      <b-modal
        id="modal-1"
        ref="my-modal"
        hide-footer
        :title="$t('modal.ifce-solidario.title')"
        centered
        @close="hideModal"
        @hidden="hideModal"
      >
        <img
          src="../assets/img/ifce-solidario.jpeg"
          class="img-fluid"
          alt="img-responsive"
        />
        <div class="d-block text-center">
          <h3 class="textocamp">{{ $t("modal.ifce-solidario.descricao") }}</h3>
        </div>

        <b-button
          class="sbm"
          id="sbm"
          block
          href="https://faifce.ifce.edu.br/ifce-solidario/covid-19"
          target="_blank"
          >{{ $t("geral.buttons.saiba-mais") }}</b-button
        >
        <b-button class="mt-3" id="fecha" block @click="hideModal">{{
          $t("geral.buttons.fechar")
        }}</b-button>
      </b-modal>
    </div>
    -->
  </div>
</template>

<script>
import Navbar from "@/layout/Navbar";
import MainHomepage from "./home/Main";
import CadastreEmpresa from "./home/CadastreEmpresa";
import Dicas from "./home/Dicas";
import Vantagens from "./home/Vantagens";
import Contato from "./home/Contato";
import Realizacao from "./home/Realizacao";
import Estatisticas from "./home/Estatisticas";
import Parceria from "./home/Parceria";
import Apoio from "./home/Apoio";
import Faq from "./home/Faq";
import Download from "./home/Download";
import FooterHomepage from "@/layout/Footer";
export default {
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$store.commit("closeModalAd");
      this.$refs["my-modal"].hide();
    }
  },
  mounted() {
    if (this.$store.state.showModalAd === true) this.showModal();
  },
  components: {
    Navbar,
    MainHomepage,
    CadastreEmpresa,
    Dicas,
    Vantagens,
    FooterHomepage,
    Realizacao,
    Estatisticas,
    Contato,
    Apoio,
    Faq,
    Download,
    Parceria
  }
};
</script>
<style>
.modal-content .sbm {
  color: #fff;
  background-color: #2ecc71;
  border-color: #2ecc71;
}
.modal-content .mt-3 #fecha {
  margin: 2px;
  width: auto;
  padding: 4 6px;
  border: none;
  color: #fff;
}
h3,
.h3 .textocamp {
  font-size: 1.15rem;
}
</style>
