<template>
  <section class="page__section page__section--cinza" ref="estatistica">
    <div class="container">
      <h2 class="text-center mt-0">
        {{ $t("home_page.titles.section-estatisticas") }}
      </h2>
      <hr class="divider my-4" />
      <div class="page__section__cards page__section__cards">
        <div
          class="page__section__cards__card"
          v-for="card in cards"
          :key="card.icon"
        >
          <div class="inform-rounded">
            <div>
              <h1 class="font-weight-bold" style="color: #27ae60;">
                <animated-number
                  :value="card.number"
                  :duration="1500"
                  :round="1"
                  ref="numbers"
                  v-if="show"
                />
              </h1>
              <h3>{{ $t("geral.labels." + card.title) }}</h3>
            </div>
          </div>
          <p class="mt-3">
            {{
              $t("home_page.descricoes.section-estatisticas-" + card.title, {
                number: card.number
              })
            }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Service from "../../services/ApiServices";
import AnimatedNumber from "animated-number-vue";

export default {
  components: {
    AnimatedNumber
  },
  data() {
    return {
      show: false,
      totalAcessos: 0,
      totalEstabelecimentos: 0,
      titlePage: "Nossos Números",
      cards: [
        {
          title: "acessos",
          number: 0
        },
        {
          title: "servicos",
          number: 0
        },
        {
          title: "cidades",
          number: 0
        },
        {
          title: "estados",
          number: 0
        }
      ]
    };
  },
  methods: {
    async getTotalAcessos() {
      const request = new Service();
      let requestResult = await request.totalAcessos(
        {},
        "usuarios/totalAcessos"
      );
      this.cards[0].number = requestResult ? requestResult.totalAcessos : 0;
    },
    async getTotalEstabelecimentos() {
      const request = new Service();
      let requestResult = await request.totalAcessos({}, "empresas/count");
      this.cards[1].number = requestResult ? requestResult : 0;
    },
    async getTotalCidades() {
      const request = new Service();
      let requestResult = await request.totalAcessos({}, "custom/countCidades");
      this.cards[2].number = requestResult ? requestResult : 0;
    },
    async getTotalEstados() {
      const request = new Service();
      let requestResult = await request.totalAcessos({}, "custom/countEstados");
      this.cards[3].number = requestResult ? requestResult : 0;
    },
    handleScroll() {
      if (window.scrollY >= this.pontoAnimacao) {
        this.show = true;
      }
    }
  },
  computed: {
    pontoAnimacao() {
      return (
        this.$refs.estatistica.offsetTop - this.$refs.estatistica.offsetHeight
      );
    }
  },
  created() {
    this.getTotalAcessos();
    this.getTotalEstabelecimentos();
    this.getTotalCidades();
    this.getTotalEstados();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>
