<template>
  <section class="page__section page__section--cinza" id="contact">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <h2 class="mt-0">{{ $t("home_page.titles.section-contato") }}</h2>
      <hr class="divider my-4" />
      <p>{{ $t("home_page.descricoes.section-contato") }}</p>
      <img
        class="contact-mail pb-3"
        src="../../assets/icons/mail-fill.svg"
        alt=""
      />
      <a class="d-block" :href="mailto">
        {{ email }}
      </a>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      titlePage: "Entre em Contato!",
      email: "contato@lar.ifce.edu.br",
      text:
        "Encontrou algum problema e deseja relatar? Envie um email para o endereço abaixo."
    };
  },
  computed: {
    mailto() {
      return `mailto:${this.email}`;
    }
  }
};
</script>
