<template>
  <section class="pt-5 pb-5 page__section--white" style="">
    <div class="container page-section d-flex flex-column align-items-center">
      <div class="text-center">
        <h2 class="pl-2 pr-2">
          {{ $t("home_page.titles.section-dicas") }}
        </h2>
      </div>
      <div class="row pt-5">
        <div
          class="d-flex justify-content-center text-center col-sm-6 col-md-6"
          v-for="(item, index) in sections"
          :key="index"
        >
          <router-link :to="`/dicas/#${item.id}`">
            <b-card
              :title="$t('home_page.labels.' + item.title)"
              class="list__item"
              :img-src="require(`../../assets/icons/dicas/${item.img}`)"
              img-alt="Image"
              img-top
              tag="article"
              style="min-width:250px; border-radius: 10px 10px 10px 10px;"
            >
            </b-card>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      titlePage: "Vantagens de Usar o #FiqueNoLar",
      sections: [
        {
          id: "delivery",
          title: "delivery-retirada",
          img: "delivery3_green.svg"
        },
        {
          id: "estabelecimento",
          title: "estabelecimento",
          img: "market4_green.svg"
        }
        // { id: "motoboy", title: "Motoboy", img: "motoboy_green.svg" },
      ]
    };
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
a {
  color: #636e79;
}
a:hover {
  text-decoration: none;
}
</style>
