<template>
  <section class="page__section--cinza pt-5 pb-5">
    <div class="container" id="download-app">
      <h2 class="text-center mt-0">
        {{ $t("home_page.titles.section-baixar-aplicativo") }}
      </h2>
      <hr class="divider my-4" />
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="row">
          <div class="text-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.rnfnl"
              target="_blank"
            >
              <img
                class="p-1"
                src="../../assets/icons/google-play-badge-black_2.svg"
                width="233px"
                height="90px"
                alt=""
              />
            </a>
            <!-- <a href=""> -->
            <!-- <img
            class="img__disabled p-1"
            src="../../assets/icons/app-store-badge-black_2.svg"
            width="233px"
            height="90px"
            alt=""
          /> -->
            <!-- </a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      titlePage: ""
    };
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
.img__disabled {
  opacity: 0.4;
}
</style>
