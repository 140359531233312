<template>
  <section class="page__section pt-0">
    <div class="container">
      <h2 class="text-center mt-0">
        {{ $t("home_page.titles.section-parceria") }}
      </h2>
      <hr class="divider my-4" />
      <div class="page__section__cards page__section__cards__3">
        <div
          class="page__section__cards__card"
          v-for="card in cards"
          :key="card.icon"
        >
          <a
            :href="card.link"
            v-if="card.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require(`../../assets/img/apoio/${card.img}`)" alt="" />
          </a>
          <img
            v-else
            :src="require(`../../assets/img/apoio/${card.img}`)"
            alt=""
            :style="`${card.size}`"
          />
          <h3>{{ card.title }}</h3>
          <p>{{ card.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      titlePage: "Parceria",
      cards: [
        {
          img: "gov_ce.svg",
          link: "",
          title: "Governo do Estado do Ceará",
          description:
            "Secretaria de Desenvolvimento Econômico e Trabalho (SEDET) e Secretária da Ciência, Tecnologia e Educação Superior (SECITECE)",
          size: "width: 100px; height: 100px;"
        },
        {
          img: "gov_ba_brasao_vert.png",
          link: "",
          title: "Governo do Estado da Bahia",
          // description: "Governo do Estado da Bahia",
          size: "width: 160px; height: 160px;"
        },
        {
          img: "mtp_logo_3.png",
          link: "",
          title: "MPT",
          description: "Ministério Público do Trabalho",
          size: "width: 160px; height: 110px;"
        }
      ]
    };
  }
};
</script>
