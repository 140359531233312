<template>
  <section class="cadastre-empresa page-section">
    <div class="container">
      <h2 class="mb-4 mt-5">
        {{ $t("home_page.titles.section-cadastrar") }}
      </h2>
      <router-link to="/form">
        <b-button
          variant="success"
          class="mb-4 text-uppercase"
          style="width: 200px"
        >
          {{ $t("geral.buttons.cadastrar") }}
        </b-button>
      </router-link>
      <p>{{ $t("home_page.descricoes.section-cadastrar") }}</p>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      message: ""
    };
  }
};
</script>

<style scoped>
.page-section {
  padding: 7rem 0;
}
</style>
