<template>
  <section class="page__section pt-0">
    <div class="container">
      <h2 class="text-center mt-0">
        {{ $t("home_page.titles.section-apoio") }}
      </h2>
      <hr class="divider my-4" />
      <div class="page__section__cards page__section__cards__3">
        <div
          class="page__section__cards__card"
          v-for="card in cards"
          :key="card.icon"
        >
          <a
            :href="card.link"
            v-if="card.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require(`../../assets/img/apoio/${card.img}`)" alt="" />
          </a>
          <img
            v-else
            :src="require(`../../assets/img/apoio/${card.img}`)"
            alt=""
          />
          <h3>{{ card.title }}</h3>
          <p>{{ card.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      titlePage: "Apoio",
      cards: [
        {
          img: "pf_aracati.png",
          link: "",
          title: "Prefeitura Municipal de Aracati"
          // description: "Prefeitura Municipal de Aracati"
        },
        {
          img: "pf_jaguaribe.png",
          link: "",
          title: "Prefeitura Municipal de Jaguaribe"
          // description: "Prefeitura Municipal de Jaguaribe"
        },
        // {
        //   img: "pf_icapui.png",
        //   link: "",
        //   title: "Prefeitura Municipal de Icapuí"
        //   // description: "Prefeitura Municipal de Icapuí"
        // },
        // {
        //   img: "ifce_sp_salto.svg",
        //   link: "http://slt.ifsp.edu.br/portal/",
        //   title: "IFSP - Campus Salto",
        //   description:
        //     "Instituto Federal de Educação, Ciência e Tecnologia de São Paulo"
        // },
        {
          img: "sindialimentos.png",
          link: "",
          title: "SindiAlimentos",
          description:
            "Sindicato das Indústrias da Alimentação e Rações Balanceadas no Estado do Ceará"
        }
      ]
    };
  }
};
</script>
